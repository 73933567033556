<template>
  <div>
    <!-- <v-container fluid class="down-top-padding"> -->
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  Affiliation Report Seller & Style Partners
                  <v-spacer></v-spacer>
                  <v-flex text-right xs12 sm4 md3 pt-2>
                    <v-select
                      dense
                      v-model="filter"
                      class="mt-5"
                      label="Select Type"
                      :items="filterArray"
                      item-text="name"
                      item-value="value"
                      outlined
                    >
                    </v-select>
                  </v-flex>
                  <v-flex text-right xs12 sm4 md3 pl-3>
                    <v-text-field
                      dense
                      v-model="search"
                      append-icon="mdi-magnify"
                      solo
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm2 pl-3>
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fromDate"
                          label="From Date"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fromDate"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm2 pl-3>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="toDate"
                          label="To Date"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="toDate"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="list"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.user`]="{ item }">
                    <span
                      >{{ item.buyer.firstname }}
                      {{ item.buyer.lastname }}</span
                    >
                  </template>
                  <template v-slot:[`item.create_date`]="{ item }">
                    <span>{{ item.create_date.slice(0, 10) }}</span>
                  </template>
                  <template v-slot:[`item.affiliator`]="{ item }">
                    <span v-if="item.affiliator"
                      >{{ item.affiliator.firstname }}
                      {{ item.affiliator.lastname }}</span
                    >
                  </template>
                  <template v-slot:[`item.productname`]="{ item }">
                    <span v-if="item.buyedProduct">{{
                      item.buyedProduct.productname
                    }}</span>
                  </template>
                  <template v-slot:[`item._id`]="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="userinfo(item.affiliator._id)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                </v-data-table>
                <div class="pt-2" v-if="pages > 1">
                  <v-pagination
                    :length="pages"
                    :total-visible="7"
                    v-model="currentPage"
                    color="#FF0000"
                  ></v-pagination>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <!-- </v-container> -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: {},
      pageCount: 1,
      appLoading: false,
      currentPage: 1,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      minrange: "",
      maxrange: "",
      amt: "",
      list: [],
      fromDate: "",
      toDate: "",
      menu: false,
      menu1: false,
      search: "",
      filterArray: [
        { name: "All", value: "" },
        { name: "Style Partner", value: "StylePartner" },
        { name: "Seller", value: "Seller" },
      ],
      filter:"",
      headers: [
        // { text: "Date", value: "create_date" },
        // { text: "User", value: "user" },
        { text: "Affiliator", value: "affiliator" },
        { text: "Phone", value: "affiliator.phone" },
        {
          text: "Total Commission Amount (Rs.)",
          value: "affiliator.totalCommissionAmount",
        },
        {
          text: "Commission Amount Settled (Rs.)",
          value: "affiliator.commissionAmountcollected",
        },
        {
          text: "Pending Commission Amount (Rs.)",
          value: "affiliator.commissionAmount",
        },
        // { text: "Coins", value: "coins" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
    fromDate() {
      this.getData();
    },
    toDate() {
      this.getData();
    },
    search() {
      this.getData();
    },
    filter() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/get/affiliation/commission/report/admin/affiliates",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          fromDate: this.fromDate,
          toDate: this.toDate,
          keyword: this.search,
          filter: this.filter,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    userinfo(uid) {
      this.$router.push("/affiliationView?id=" + uid + "&type=seller" + "&fromDate=" + this.fromDate + "&toDate=" + this.toDate);
    },
  },
};
</script>

<style scoped>
.table-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>